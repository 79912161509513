<template>
  <!-- <div class="chargeIndex">
    <NavBar :list="navList"></NavBar>
    <div>
      <router-view></router-view>
    </div>
  </div> -->
      <router-view></router-view>
</template>
<script>
export default {
  data() {
    return {
      navList: [
        {
          path: '/charge/allrent',
          name: '费用总览',
          jurisdictionId: '1103010000',
        },
        {
          path: '/merchant/manage',
          name: '商户账单',
          jurisdictionId: '1103020000',
        },
        {
          path: '/merchant/setting',
          name: '收费设置',
          jurisdictionId: '1103030000',
        },
        {
          path: '/merchant/setting',
          name: '收费凭证',
          jurisdictionId: '1103030000',
        },
        {
          path: '/merchant/setting',
          name: '线下收费',
          jurisdictionId: '1103030000',
        },
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
.chargeIndex {
}
</style>
