<template>
  <div class="chargepreview">
    <NavBar :list="[]" name="收费概览"></NavBar>
    <div class="public-box">
      <div class="box">
        <div class="list">
          <div class="pub">
            <img src="../../../assets/img/Frame.png" />
            <div>
              <p>
                {{ (chargeOverview && chargeOverview.rentInfo.rentTotal) || 0 }}
              </p>
              <span>基础费用欠费户数(个)</span>
            </div>
          </div>
          <div class="pub">
            <img src="../../../assets/img/Frame1.png" />
            <div>
              <p>
                {{
                  (chargeOverview && chargeOverview.rentInfo.rentTotalPrice) ||
                  0
                }}
              </p>
              <span>欠费总金额(元)</span>
            </div>
          </div>
        </div>
        <div class="list">
          <div class="pub">
            <img src="../../../assets/img/Frame2.png" />
            <div>
              <p>
                {{
                  (chargeOverview &&
                    chargeOverview.rentInfo.rentPayableTotal) ||
                  0
                }}
              </p>
              <span>设定时间内应缴费户数(个)</span>
            </div>
          </div>
          <div class="pub">
            <img src="../../../assets/img/Frame3.png" />
            <div>
              <p>
                {{
                  (chargeOverview &&
                    chargeOverview.rentInfo.rentPayablePrice) ||
                  0
                }}
              </p>
              <span>应缴总金额(元)</span>
            </div>
          </div>
        </div>
        <div class="list">
          <div class="pub">
            <img src="../../../assets/img/Frame4.png" />
            <div>
              <p>
                {{
                  (chargeOverview && chargeOverview.dailyInfo.dailyTotal) || 0
                }}
              </p>
              <span>日常费用欠费户数(个)</span>
            </div>
          </div>
          <div class="pub">
            <img src="../../../assets/img/Frame5.png" />
            <div>
              <p>
                {{
                  (chargeOverview &&
                    chargeOverview.dailyInfo.dailyTotalPrice) ||
                  0
                }}
              </p>
              <span>日常欠费总金额(元)</span>
            </div>
          </div>
        </div>
        <div class="list">
          <div class="pub">
            <img src="../../../assets/img/Frame6.png" />
            <div>
              <p>
                {{
                  (chargeOverview &&
                    chargeOverview.dailyInfo.dailyPayableTotal) ||
                  0
                }}
              </p>
              <span>今日缴费户数(个)</span>
            </div>
          </div>
          <div class="pub">
            <img src="../../../assets/img/Frame7.png" />
            <div>
              <p>
                {{
                  (chargeOverview &&
                    chargeOverview.dailyInfo.dailyPayablePrice) ||
                  0
                }}
              </p>
              <span>缴费总金额(元)</span>
            </div>
          </div>
        </div>
        <div class="list">
          <div class="pub">
            <img src="../../../assets/img/Frame8.png" />
            <div>
              <p>
                {{
                  (chargeOverview && chargeOverview.marketInfo.fixedTotal) || 0
                }}
              </p>
              <span>固定金未交户数(个)</span>
            </div>
          </div>
          <div class="pub">
            <img src="../../../assets/img/Frame9.png" />
            <div>
              <p>
                {{
                  (chargeOverview &&
                    chargeOverview.marketInfo.fixedTotalPrice) ||
                  0
                }}
              </p>
              <span>固定金未缴总金额(元)</span>
            </div>
          </div>
        </div>
        <div class="list">
          <div class="pub">
            <img src="../../../assets/img/Frame10.png" />
            <div>
              <p>
                {{
                  (chargeOverview && chargeOverview.marketInfo.refundTotal) || 0
                }}
              </p>
              <span>退款户数(个)</span>
            </div>
          </div>
          <div class="pub">
            <img src="../../../assets/img/Frame11.png" />
            <div>
              <p>
                {{
                  (chargeOverview &&
                    chargeOverview.marketInfo.refundTotalPrice) ||
                  0
                }}
              </p>
              <span>退款总金额(元)</span>
            </div>
          </div>
        </div>
      </div>
      <div class="main">
        <NavBar :list="navbar" name="" :router="false" @change="barChange"></NavBar>
        <keep-alive>
          <component v-bind:is="currentTabComponent" class="tab"></component>
        </keep-alive>
      </div>
    </div>
  </div>
</template>
<script>
import pays from "@/views/charge/chargepreview/pays";
import chargesTable from "@/views/charge/chargepreview/chargesTable";
import { rolesID } from "@/plugin/navlist";
export default {
  components: {
    pays,
    chargesTable,
  },
  data () {
    return {
      navbar: [
        {
          path: "chargesTable",
          name: "收费情况表",
          jurisdictionId: "10050101000000000000T1",
        },
        {
          path: "pays",
          name: "缴费户数统计表",
          jurisdictionId: "10050102000000000000T1",
        },
      ],
      currentTab: "chargesTable",
      chargeOverview: null,
    };
  },
  computed: {
    currentTabComponent: function () {
      return this.currentTab;
    },
  },
  created () {
    this.getChargeOverview();
    // for (let i = 0; i < this.navbar.length; i++) {
    //   if (rolesID.includes(this.navbar[i].jurisdictionId)) {
    //     this.currentTab = this.navbar[i].path
    //     break;
    //   }
    // }
  },
  methods: {
    getChargeOverview () {
      this.$request.HttpGet("/index/chargeOverview").then((res) => {
        this.chargeOverview = res.data;
      });
    },
    barChange (e) {
      if (e == 0) {
        this.currentTab = "chargesTable";
      } else {
        this.currentTab = "pays";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.chargepreview {
  min-width: 550px;

  .main {
    /deep/ .navBarlist {
      .title {
        min-width: 40px !important;
      }
    }
  }

  .box {
    width: 100%;
    flex-wrap: wrap;
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(auto-fill, 550px);
    grid-gap: 10px;

    .list {
      width: 550px;
      height: 140px;
      background: #ffffff;
      opacity: 1;
      border: 1px solid #dedede;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 80px;
      box-sizing: border-box;

      .pub {
        width: 50%;
        display: flex;
        align-items: center;

        img {
          width: 40px;
          height: 40px;
          margin-right: 10px;
        }

        p {
          font-size: 24px;
          font-weight: bold;
          color: #333333;
        }

        span {
          font-size: 12px;
          font-weight: 400;
          color: #999999;
        }
      }
    }
  }
}
</style>
