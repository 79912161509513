<template>
  <div class="cjargesTable">
    <div style="margin: 15px">
      <!-- <el-select v-model="form.integral" placeholder="选择费用类型" class="margin-B15">
      <el-option v-for="item in merchantStatus" :key="item.value" :label="item.label" :value="item.value"></el-option>
    </el-select>
    <MyButton left @click="dialogVisible=true">柱状图对比
      <template slot="preImage">
        <img src="../../../unit/img/search.png" alt="">
      </template>
    </MyButton> -->
      <MyButton title="导出" :accessId="39311" @click="statisticsExport">
        <template slot="preImage">
          <img src="../../../unit/img/down.png" alt="" />
        </template>
      </MyButton>
    </div>
    <div class="public-table" style="margin-top: 20px">
      <el-table
        :data="tableData"
        :header-cell-style="{
          'text-align': 'center',
          background: 'rgb(245, 245, 245)',
        }"
        :cell-style="{ 'text-align': 'center' }"
        style="width: 100%"
      >
        <el-table-column align="right">
          <template slot="header">
            <el-date-picker
              style="width: 100px"
              v-model="year"
              format="yyyy"
              value-format="yyyy"
              class="diy"
              type="year"
              @change="changeYear"
              placeholder="选择年"
            ></el-date-picker>
          </template>
          <template slot-scope="scope">{{ scope.row.month }}</template>
        </el-table-column>
        <el-table-column label="应收费用" prop="info.receivable">
        </el-table-column>
        <el-table-column label="实收费用" prop="info.receivedPrice">
        </el-table-column>
        <el-table-column label="未收费用" prop="info.uncollected">
        </el-table-column>
        <el-table-column label="减免金额" prop="info.couponPrice">
        </el-table-column>
        <el-table-column label="抹零金额" prop="info.zeroPrice">
        </el-table-column>
        <el-table-column label="优惠费用" prop="info.discountMoney">
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      title="收费情况表"
      :visible.sync="dialogVisible"
      :append-to-body="true"
      width="80%"
      :close-on-click-modal="false"
    >
      <el-select
        v-model="form.integral"
        placeholder="选择费用类型"
        class="offset margin-B15"
      >
        <el-option
          v-for="item in merchantStatus"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
      <div
        id="echartsMain"
        ref="echartsMain"
        style="width: 100%; height: 400px"
      ></div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "chargesTable",
  data() {
    return {
      dialogVisible: false,
      form: {
        merchantName: "",
        integral: "",
      },
      tableData: [],
      search: "",
      year: "",
      yearArr: [
        {
          value: "1",
          label: "2021年",
        },
        {
          value: "2",
          label: "2022年",
        },
      ],
      merchantStatus: [
        {
          value: "1",
          label: "全部",
        },
        {
          value: "2",
          label: "基础费用",
        },
        {
          value: "3",
          label: "保证金",
        },
        {
          value: "4",
          label: "日常费用",
        },
      ],
    };
  },
  created() {
    this.getStatistics();
  },
  methods: {
    statisticsExport() {
      this.$exported(
        "/bill/statisticsExport",
        { year: this.year },
        "收费情况表.xlsx"
      );
    },
    getStatistics() {
      this.$request
        .HttpGet("/bill/statistics", {
          year: this.year,
        })
        .then((res) => {
          this.tableData = res.data;
        });
    },
    changeYear() {
      this.getStatistics();
    },
    filterHandler(value, row, column) {
      const property = column["property"];
      return row[property] === value;
    },
    init() {
      let mychart = this.$echarts.init(this.$refs.echartsMain);
      let option;
      option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        toolbox: {
          feature: {
            saveAsImage: {
              show: true,
            },
          },
        },
        legend: {
          data: ["Evaporation", "Precipitation", "Temperature", "3", "4"],
        },
        xAxis: [
          {
            type: "category",
            data: [
              "一月",
              "二月",
              "三月",
              "四月",
              "五月",
              "六月",
              "七月",
              "八月",
              "九月",
              "十月",
              "十一月",
              "十二月",
            ],
            axisPointer: {
              type: "shadow",
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "Precipitation",
            min: 0,
            max: 250,
            interval: 50,
            axisLabel: {
              formatter: "{value}",
            },
          },
        ],
        series: [
          {
            name: "Evaporation",
            type: "bar",
            itemStyle: {
              borderColor: "#02CE80",
              color: "#02CE80",
            },
            tooltip: {
              valueFormatter: function (value) {
                return value + " ml";
              },
            },
            data: [
              2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4,
              3.3,
            ],
          },
          {
            name: "Precipitation",
            type: "bar",
            itemStyle: {
              borderColor: "#528CFF",
              color: "#528CFF",
            },
            tooltip: {
              valueFormatter: function (value) {
                return value + " ml";
              },
            },
            data: [
              2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0,
              2.3,
            ],
          },
          {
            name: "3",
            type: "bar",
            itemStyle: {
              borderColor: "#D4D4D4",
              color: "#D4D4D4",
            },
            tooltip: {
              valueFormatter: function (value) {
                return value + " ml";
              },
            },
            data: [
              2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0,
              2.3,
            ],
          },
          {
            name: "4",
            type: "bar",
            itemStyle: {
              borderColor: "#F8C405 ",
              color: "#F8C405 ",
            },
            tooltip: {
              valueFormatter: function (value) {
                return value + " ml";
              },
            },
            data: [
              2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0,
              2.3,
            ],
          },
        ],
      };

      option && mychart.setOption(option);
    },
  },
};
</script>

<style scoped lang="scss">
.cjargesTable {
  .diy {
    /deep/ .el-input__inner {
      background: none;
      border: none;
      text-align: center;
    }
  }

  /deep/ .el-table .el-table__cell {
    padding: 0;
    height: 38px;
  }
}
</style>
