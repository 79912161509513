<template>
<div class="pays">
  <div style="margin:15px">

    <!-- <el-select v-model="form.integral" placeholder="选择费用类型" class="margin-B15">
      <el-option v-for="item in merchantStatus" :key="item.value" :label="item.label" :value="item.value"></el-option>
    </el-select>
    <MyButton left @click="dialogVisible=true">柱状图对比
      <template slot="preImage">
        <img src="../../../unit/img/search.png" alt="">
      </template>
    </MyButton> -->
    <MyButton title="导出" :accessId="16979" @click="statisticsExport">
      <template slot="preImage">
        <img src="../../../unit/img/down.png" alt="">
      </template>
    </MyButton>

  </div>
  <div class="public-table" style="margin-top: 20px">
    <el-table :data="tableData.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()))" :header-cell-style="{'text-align':'center','background':'rgb(245, 245, 245)'}" :cell-style="{'text-align':'center'}"
      style="width: 100%">
      <el-table-column align="right">
        <template slot="header">
          <el-date-picker v-model="year" style="width:100px" format="yyyy" @change="changeYear" value-format="yyyy" class="diy" type="year" placeholder="选择年"></el-date-picker>
        </template>
        <template slot-scope="scope">
          {{scope.row.month}}
        </template>
      </el-table-column>
      <el-table-column label="总商户数" prop="info.totalPeople">
      </el-table-column>
      <el-table-column label="缴费商户数" prop="info.payPeople">
      </el-table-column>
      <el-table-column label="未缴费商户数" prop="info.notPeople">
      </el-table-column>

    </el-table>
  </div>
  <!-- <el-dialog title="收费情况表" :visible.sync="dialogVisible" :append-to-body="true" width="80%">
    <el-select v-model="form.integral" placeholder="选择费用类型" class="offset margin-B15">
      <el-option v-for="item in merchantStatus" :key="item.value" :label="item.label" :value="item.value"></el-option>
    </el-select>
    <div id="echartsMain" ref="echartsMain" style="width: 100%;height: 400px"></div>
  </el-dialog> -->
</div>
</template>

<script>
export default {
  name: "pays",
  data() {
    return {
      dialogVisible: false,
      form: {
        merchantName: "",
        integral: ''
      },
      tableData: [],
      search: '',
      year: ''
    }
  },
  created() {
    this.getStatistics();
  },
  methods: {
    statisticsExport() {
      this.$exported('/bill/statisticsPeopleExport', { year: this.year }, '缴费户数统计表.xlsx');
    },
    changeYear() {
      this.getStatistics();
    },
    getStatistics() {
      this.tableData = []
      this.$request.HttpGet('/bill/statisticsPeople', {
        year: this.year
      }).then(res => {
        if(res.data){
        this.tableData = res.data;
        }
      });
    },
    filterHandler(value, row, column) {
      const property = column['property'];
      return row[property] === value;
    },
    init() {
      let mychart = this.$echarts.init(this.$refs.echartsMain);
      let option;
      option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        toolbox: {
          feature: {
            saveAsImage: {
              show: true
            }
          }
        },
        legend: {
          data: ['Evaporation', 'Precipitation', 'Temperature', '3', '4']
        },
        xAxis: [{
          type: 'category',
          data: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
          axisPointer: {
            type: 'shadow'
          }
        }],
        yAxis: [{
          type: 'value',
          name: 'Precipitation',
          min: 0,
          max: 250,
          interval: 50,
          axisLabel: {
            formatter: '{value}'
          }
        }],
        series: [{
            name: 'Evaporation',
            type: 'bar',
            itemStyle: {
              borderColor: '#02CE80',
              color: '#02CE80'
            },
            tooltip: {
              valueFormatter: function(value) {
                return value + ' ml';
              }
            },
            data: [
              2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3
            ]
          },
          {
            name: 'Precipitation',
            type: 'bar',
            itemStyle: {
              borderColor: '#528CFF',
              color: '#528CFF'
            },
            tooltip: {
              valueFormatter: function(value) {
                return value + ' ml';
              }
            },
            data: [
              2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3
            ]
          },
          {
            name: '3',
            type: 'bar',
            itemStyle: {
              borderColor: '#D4D4D4',
              color: '#D4D4D4'
            },
            tooltip: {
              valueFormatter: function(value) {
                return value + ' ml';
              }
            },
            data: [
              2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3
            ]
          },
          {
            name: '4',
            type: 'bar',
            itemStyle: {
              borderColor: '#F8C405 ',
              color: '#F8C405 '
            },
            tooltip: {
              valueFormatter: function(value) {
                return value + ' ml';
              }
            },
            data: [
              2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3
            ]
          },
        ]
      }

      option && mychart.setOption(option);

    }
  }
}
</script>

<style scoped lang="scss">
.pays {
    .diy {
        /deep/ .el-input__inner {
            background: none;
            border: none;
            text-align: center;
        }

    }
    /deep/ .el-table .el-table__cell {
        padding: 0;
        height: 38px;
    }
}
</style>
